import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteBanner, fetchBanner, fileDelete } from "../api/fetchApi";

function BannerConfig() {
  const [banner, setBanner] = useState([]);

  const getBanner = async () => {
    let bannerRes = await fetchBanner();
    setBanner(bannerRes.data);
  };

  useEffect(() => {
    getBanner();
  }, []);

  const handleDelete = async (id, filename) => {
    await deleteBanner(id)
      .then(async () => {
        getBanner();

        await fileDelete(filename)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => {
        console.log(error);
        alert("Error !!! Record Not Deleted");
      });
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h4>Banner Files</h4>
            <div className="m-2">
              <Link to={"/admin111/create"}>
                <button className="btn btn-primary">New Banner</button>
              </Link>
            </div>

            <table className="table table-striped table-bordered table-hover">
              <thead className="table-primary">
                <th>File Name</th>
                <th>Order</th>
                <th>Active</th>
                <th>Delete</th>
              </thead>
              <tbody>
                {banner.map((result, index) => {
                  return (
                    <tr>
                      <td>{result.filename}</td>
                      <td>{result.sort}</td>
                      <td>{result.active ? "Yes" : "No"}</td>
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            if (window.confirm("Delete The Record ?")) {
                              handleDelete(result.id, result.filename);
                            }
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerConfig;
