import { useEffect, useState } from "react";

import "./App.css";
import "./Style.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./component/Home";
import { Helmet } from "react-helmet";
import Product from "./component/Product";
import ProductDetails from "./component/ProductDetails";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Contact from "./component/Contact";
import About from "./component/About";
import BannerConfig from "./component/BannerConfig";
import BannerCreate from "./component/BannerCreate";
import XlsFileupload from "./component/XlsfileUpload";

function App() {
  const [search, setSearch] = useState(null);
  useEffect(() => {
    if (!search) {
      setSearch(null);
    }
  }, [search]);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home onClick={(value) => setSearch(value)} search={search} />,
    },
    {
      path: "/product",
      element: <Product search={search} />,
    },
    {
      path: "/product/:id",
      element: <ProductDetails search={search} />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/admin111",
      element: <BannerConfig />,
    },
    {
      path: "/admin111/create",
      element: <BannerCreate />,
    },
    {
      path: "/admin111/xlsfileupload",
      element: <XlsFileupload />,
    },
  ]);
  return (
    <>
      <Helmet>
        <script src="script.js" type="text/javascript" />
      </Helmet>
      <Header onClick={(value) => setSearch(value)} search={search} />

      <RouterProvider router={router}></RouterProvider>

      <Footer />
    </>
  );
}

export default App;
