import React from "react";
const About = () => {
  return (
    <>
      {" "}
      <div id="body_wrapper">
        <div class="container">
          <div class="about-us">
            <h2>About us</h2>
            <p>
              Welcome to the online home of Ruby Wines, your go-to destination
              for all your liquor needs. Our store is a family-owned and
              operated business that has been serving the community for 35
              years.
            </p>
            <p>
              At Ruby Wines, we pride ourselves on offering a wide selection of
              high-quality liquors, wines, and beers to suit every taste and
              budget. Whether you're looking for a premium scotch, a locally
              brewed craft beer, or a bottle of your favorite wine, we've got
              you covered.
            </p>
            <p>
              Our team of knowledgeable staff members is always on hand to help
              you find the perfect bottle for any occasion, whether you're
              celebrating a special milestone or simply relaxing with friends.
              We're passionate about sharing our love of liquor with our
              customers, and we're always happy to offer advice and
              recommendations to help you make the right choice.
            </p>
            <p>
              At Ruby Wines, we're committed to providing our customers with the
              best possible shopping experience. That's why we offer competitive
              prices, convenient online ordering, and fast, reliable shipping.
              We want you to feel confident and satisfied with every purchase
              you make from us, and we're always here to answer any questions or
              address any concerns you may have.
            </p>
            <p>
              Thank you for choosing Ruby Wines for all your liquor needs. We
              look forward to serving you and becoming your trusted source for
              all things liquor-related.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default About;
