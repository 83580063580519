import React, { useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { fetchProductDetailList } from "../api/fetchApi";

import Vector from "../assets/images/vector-img.jpeg";
import Product from "./Product";

const ProductDetails=(props)=>{
    console.log("props",props.search)
    const [productList,setProductList] = useState([])

    const {id} = useParams()
    const productListHandler = async(ids) => {

            let productRes = await fetchProductDetailList(ids)
            setProductList(productRes.data)
    }
    
    useEffect(()=>{
        if(id){
            productListHandler(id)
        }
    },[])

        return(<>

    {props.search? <Product search={props.search}/> : <div id="body_wrapper">
        <section>
            <div class="shopProd_mn shopAll_mn">
                <div class="container">
                    <div class="shopProd_title">
                        <h2>Featured Wines</h2>
                    </div>
                    <div class="shopProd_list animatedParent animateOnce">
                    {productList.map(result=>{
                            return(<div class="shopProd_item animated" >
                            <div class="box">
                                <div class="top_part">
                                    <div class="img_wrap">
                                        <img src={Vector} alt="Red Wine" />
                                    </div>
                                    <div class="detail">
                                        <div class="category">
                                            <span class="main">{result.subcategory}</span> / <span class="sub">{result.subsubcategory}</span>
                                        </div>
                                        <div class="title">
                                            <h3>{result.item}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="bottom_part">
                                    <div class="list">
                                        <ul>
                                        {result.itdetails.map((itm)=>{
                                            return(
                                            <li>
                                                <span class="winQty">{itm.ml}ML</span><span class="winPrc">
                                                    
                                                    {itm.salerate !== itm.rate && <small>₹{itm.rate}</small>}
                                                    
                                                    ₹{itm.salerate}</span>
                                            </li>
                                            
                                        )
                                        })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>)
                        })}
                    </div>                    
                </div>
            </div>
        </section>
    </div>}
        </>)
}
export default ProductDetails