import React, { useState, useEffect } from "react";
// import Vector from "../assets/images/vector-img.jpeg";
import Slider from "react-slick";
import { fetchBestDeal } from "../api/fetchApi";

const BestDeal = () => {
  const [dealList, setDealList] = useState([]);

  const bestDealHandler = async () => {
    let dealRes = await fetchBestDeal();
    setDealList(dealRes.data);
  };
  useEffect(() => {
    bestDealHandler();
  }, []);
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    speed: 1500,
    arrows: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section>
      <div class="shopProd_mn">
        <div class="container">
          <div class="shopProd_title text-center">
            <h2>Best Deals</h2>
          </div>
          <div class="shopProd_list shopProd_slider">
            <Slider {...settings}>
              {dealList.map((result) => {
                return (
                  <div class="shopProd_item">
                    <div class="shopProd_box">
                      {/* <div class="shopProd_img">
                        <figure class="shopProd_img_wrapper">
                          <img src={Vector} alt="Wine" />
                        </figure>
                      </div> */}
                      <div class="shopProd_detail">
                        <div class="category">
                          <span class="main">{result.category}</span> /{" "}
                          <span class="sub">{result.subcategory}</span>
                        </div>
                        <div class="title-part border-top border-bottom pt-2 pb-2">
                          <h3 class="title">{result.item}</h3>
                        </div>
                        <div class="list">
                          <ul>
                            {result.itdetails.map((itm) => {
                              return (
                                <li>
                                  <span class="winQty">{itm.ml} ML</span>
                                  <span class="winPrc">
                                    {itm.salerate !== itm.rate && (
                                      <small>₹{itm.rate}</small>
                                    )}
                                    ₹{itm.salerate}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};
export default BestDeal;
