import React from "react";
import Product from "./Product";
import Banner from "./Banner";
import Category from "./Category";
import BestDeal from "./BestDeal";

const Home = (props) => {
  return (
    <>
      {!props.search ? (
        <div id="body_wrapper">
          <Banner />
          <BestDeal />

          <Category />
          <section>
            <div class="inner-main-section main-contact-section">
              <div class="container">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div class="form-wrapper">
                      <h4 class="main-title">Feel free to contact us</h4>
                      <div class="main-subtitle">
                        {" "}
                        For more details or for any enquiry, drop your details
                        below, we will call you soon{" "}
                      </div>
                      <div class="contact-form">
                        <form>
                          <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input
                                  type="text"
                                  name="name"
                                  class="form-control"
                                  placeholder="Name"
                                />
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input
                                  type="text"
                                  name="cname"
                                  class="form-control"
                                  placeholder="Company Name"
                                />
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input
                                  type="email"
                                  name="cname"
                                  class="form-control"
                                  placeholder="Email"
                                />
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input
                                  type="number"
                                  name="cname"
                                  class="form-control"
                                  placeholder="Phone Number"
                                />
                              </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <div class="form-group">
                                <textarea
                                  class="form-control"
                                  rows="8"
                                  placeholder="Enter Your Message"
                                ></textarea>
                              </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <div class="form-group button button-2">
                                <button type="submit" class="btn">
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 map_wrap">
                    <div class="main-map">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.093183297521!2d72.850969!3d19.0596402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c96a1ef437cd%3A0xac44e3ea04413d20!2sRuby%20wines!5e0!3m2!1sen!2sin!4v1688800543845!5m2!1sen!2sin"
                        title="Ruby Wines"
                        width="600"
                        height="450"
                        // style="border: 0"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <Product search={props.search} />
      )}
    </>
  );
};
export default Home;
