import React, { useEffect, useState } from "react";
import { fetchBanner } from "../api/fetchApi";
import Slider from "react-slick";

const Banner = () => {
  const [banner, setBanner] = useState([]);

  const getBanner = async () => {
    let bannerRes = await fetchBanner();
    setBanner(bannerRes.data);
  };
  useEffect(() => {
    getBanner();
  }, []);
  let settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    speed: 1500,
    arrows: true,
    responsive: [
      {
        breakpoint: 767,
        settings: { slidesToShow: 1, arrows: false, autoplay: true },
      },
    ],
  };
  return (
    <>
      <section>
        <div class="bannerSec fullImgBanner">
          <div class="banner_sl">
            <Slider {...settings}>
              {banner.map((result, index) => {
                return (
                  <img
                    alt="Give Shape towards your Ideas"
                    width="100%"
                    height="650"
                    src={"../public/images/" + result.filename}
                    class="lazy"
                  />
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};
export default Banner;
