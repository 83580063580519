import React from "react";
import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import config from "../config.json";
// import config from "./config.json";

function BannerCreate() {
  const url = config.REACT_APP_URL;
  const navigate = useNavigate();
  const [sort, setSort] = useState(0);
  const [active, setActive] = useState(true);
  const [filename, setFilename] = useState("");
  const [filehandle, setFilehandle] = useState("");
  const [errmessage, setErrmessage] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("mFile", filehandle);
    console.log(e);
    axios
      .post(url + "/products/bannercreate/", {
        filename: filename,
        sort: sort,
        active: active ? 1 : 0,
      })
      .then((response) => {
        console.log(response.data);
        axios
          .post(url + "/products/fileupload/", formdata, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            console.log(response.data);
            navigate("/admin111");
          })
          .catch((error) => {
            console.log(error);
            setErrmessage(error.response.data.msg);
            alert(
              error.response.data.msg + " Error!!! cannot save image file "
            );
          });
      })
      .catch((error) => {
        console.log(error);
        setErrmessage(error.response.data.msg);
        alert(error.response.data.msg + " !!! Error ! cannot save record");
      });
  };

  const fileChange = (e) => {
    setFilename(e.target.files[0].name);
    setFilehandle(e.target.files[0]);
  };

  return (
    <>
      <div className="container" style={{ height: "400px" }}>
        <div className="row">
          <div className="col-8 center">
            <div className="bg-primary p-2 text-center mb-2">Create Banner</div>
            <Link to="/admin111">
              <button className="btn btn-primary">Banner Records</button>
            </Link>
            <form onSubmit={handleSubmit} enctype="multipart/form-data">
              <div className="row">
                <div className="form-group">
                  <div className="d-inline-block">
                    Select File (1280 x 400)pixels: &nbsp;
                  </div>
                  <div className="d-inline-block">
                    <input
                      type="file"
                      name="filename"
                      accept=".jpeg, .jpg, .png"
                      onChange={(e) => fileChange(e)}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <div className="d-inline-block">Sort: &nbsp;</div>
                  <div className="d-inline-block">
                    <input
                      className="form-control"
                      type="number"
                      style={{ padding: "0", width: "50px" }}
                      value={sort}
                      onChange={(e) => setSort(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <div className="d-inline-block">Active: &nbsp;</div>
                  <div className="d-inline-block">
                    <input
                      type="checkbox"
                      style={{ padding: "0", width: "50px" }}
                      checked={active}
                      onChange={(e) => setActive(e.target.checked)}
                    />
                  </div>
                </div>
              </div>
              <div>
                <button type="submit" value="Submit">
                  Submit
                </button>
              </div>
              <br />
              <br />
              <div style={{ color: "red" }}>{errmessage}</div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerCreate;
