import React, { useEffect, useRef, useState } from "react";

const Search = ({ onClick, search }) => {
  const [opensearch, setOpensearch] = useState(false);
  const searchRef = useRef("");

  useEffect(() => {
    setTimeout(() => {
      handleFocus();
    }, 300);
  }, [opensearch]);

  function handleFocus() {
    searchRef.current.focus();
  }

  return (
    <div class="search-action header-search animated fadeIn">
      <a
        class="navbar-brand search-link"
        href="javascript:void(0);"
        title="Product Search"
      >
        <img
          alt="Product Search"
          class="search-icon"
          src="https://www.ecopack.me/public/front/assets/images/search-icon.svg"
          onClick={() => setOpensearch(true)}
        />
        <img
          alt="Product Search"
          class="close-icon"
          src="https://www.ecopack.me/public/front/assets/images/close.png"
          onClick={() => onClick(null)}
        />
      </a>
      <div class="form-dropdown">
        <input
          class="form-control product_input form-input newOrderItemName"
          ref={searchRef}
          placeholder="Search"
          type="text"
          autocomplete="off"
          value={search}
          onChange={(e) => onClick(e.target.value)}
        />
      </div>
    </div>
  );
};

export default Search;
