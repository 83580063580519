import React from "react";
const Contact = () => {
  return (
    <>
      <div id="body_wrapper">
        <div class="container">
          <div class="contact-us">
            <h2>Contact Us</h2>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div class="form-wrapper">
                  <p>
                    We would love to hear from you! If you have any questions,
                    comments, or concerns about our products or services, please
                    don't hesitate to get in touch with us.
                  </p>
                  <p>
                    You can reach us by phone, email, or mail using the
                    information below:
                  </p>
                  <p class="contact-para">
                    <span>
                      Phone: <a href="tel:+917090703000">7090703000</a>
                    </span>
                    <span>
                      Email:{" "}
                      <a href="mailto:rubywinesbandra@gmail.com">
                        rubywinesbandra@gmail.com
                      </a>
                    </span>
                    Address: 16-3/7, Shastri Nagar, MIG Colony, Bandra(East)
                    Mumbai-400051.
                  </p>
                  <p>
                    Our friendly and knowledgeable staff members will be happy
                    to assist you with any questions you may have and help you
                    find the perfect products for your needs.
                  </p>
                  <p>
                    Alternatively, you can also reach out to us through the
                    contact form below. Simply fill in your name, email address,
                    and message, and we will get back to you as soon as
                    possible.
                  </p>
                  <p>
                    Thank you for choosing Ruby Wines for all your liquor needs.
                    We appreciate your business and look forward to hearing from
                    you soon!
                  </p>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 map_wrap">
                <div class="main-map">
                  <p>
                    If you would like to visit our store in person, we are
                    located at{" "}
                  </p>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.093183297521!2d72.850969!3d19.0596402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c96a1ef437cd%3A0xac44e3ea04413d20!2sRuby%20wines!5e0!3m2!1sen!2sin!4v1688800543845!5m2!1sen!2sin"
                    title="Ruby Wines"
                    width="600"
                    height="450"
                    // style="border: 0"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contact;
