import React, {useState, useEffect} from "react";
import { fetchSubCategory } from "../api/fetchApi";
import { useNavigate } from "react-router-dom";

const Category = () =>{
    const [subCategory,setSubCategory] = useState([])
    const [search,setSearch] = useState(null)
    let navigate = useNavigate();

    const bannerArr = []
    
    const getSubCategory = async() =>{
        let subCategoryRes = await fetchSubCategory();
        setSubCategory(subCategoryRes.data)
    }
   
    useEffect( ()=>{
     
        getSubCategory()
    },[])

  var settingss = {
    slidesToShow: 4,
    // slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2000,
    // infinite: true,
    // speed: 1500,
    // appendDots: dots => <ul>{dots}</ul>,
    // arrows: true,
    dots: true,
    // dotsClass: "slick-dots ",
    responsive: [
        {breakpoint: 767, settings: {arrows: false, slidesToShow: 1, slidesToScroll: 1}},
        {breakpoint: 991, settings: {arrows: false, slidesToShow: 1, slidesToScroll: 1}},
        {breakpoint: 1199, settings: {arrows: false, slidesToShow: 1, slidesToScroll: 1}},
        {breakpoint: 1200, settings: {arrows: false, slidesToShow: 1, slidesToScroll: 1}}

    ]
  };
    return(            <section>
                <div class="category_mn">
                    <div class="container">
                        <div class="shopProd_title">
                            <h2>Featured Wines</h2>
                        </div>
                        <div class="category_list ">
                        <ul class="d-flex flex-row flex-wrap justify-content-center">

                        {/* <Slider {...settingss}> */}
                            {subCategory.map((result,i)=>{
                                  return(
                                        
                                              <li>
                                                      <div class="catBtn">
                                                        <a onClick={()=>navigate(`product/${result.subcategory}`)} key={`${result.subcategory}_${i}`}>
                                                          {result.subcategory}
                                                        </a>
                                                      </div>
                                                    </li>
                                               
                                          )
                            //     return (
                            //     <div class="shopProd_item" onClick={()=>navigate(`product/${result.subcategory}`)} key={`${result.subcategory}_${i}`}>
                            //     <div class="shopProd_box">
                            //         <div class="shopProd_img">
                            //             <figure class="shopProd_img_wrapper">
                            //                 <img src={imgRes ? require(`../assets/images/GN.png`) : require(`../assets/images/beer.png`)} alt="Spirits" />
                            //             </figure>
                            //         </div>
                            //         <div class="shopProd_detail">
                            //             <h3 class="title">{result.subcategory}</h3>
                            //         </div>
                            //     </div>
                            // </div>)
                            })}
                            {/* </Slider> */}
                            </ul>
                        </div>                    
                    </div>
                </div>
            </section>)
}
export default Category